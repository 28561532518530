import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
import './slider.css';
import React from "react";

const isValidUrl = urlString=> {
  var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
  '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
return !!urlPattern.test(urlString);
}

function UncontrolledExample(props) {
  ////////////////first image///////////////

  return (
    <div className="container-fluid mt-3">
{/*maping to api to slider */}
{ props.headerObj.sliders && props.headerObj.sliders.length > 0 && <div>       
  
  <div className="row justify-content-center"  style={{'min-height':'160px','height':'100%'}}>
      <div className="col-12 ">
    <Carousel className="carousel"
    interval={4000}
    adaptiveHeight={false}
     >
      {props.headerObj.sliders.map((Slider, index) => (
 <Carousel.Item className="slide" >
 <img
   className="w-100 "
   src={isValidUrl(Slider.image)?Slider.image:(Slider.image)}
   alt="First slide"
 />
 
</Carousel.Item>



      ) )}
     
    </Carousel>
    </div>
    </div>
     </div>}
    
    </div>
  );
}

export default UncontrolledExample;
