import 'bootstrap/dist/css/bootstrap.css';
import React,{useState,useEffect} from 'react';
import './info.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { useJsApiLoader } from '@react-google-maps/api';
import { IoLocation } from "react-icons/io5";
import CurrentLocation from './CurrentLocation';
import {FaRegUser} from "react-icons/fa";
import uploadFileToBlob, { isStorageConfigured } from '../recorder/azureBlob';
import {useRef} from 'react';
import { FiSend } from "react-icons/fi";
import axios from "axios";
import { GiDeliveryDrone } from "react-icons/gi";
import { useMediaQuery } from 'react-responsive';
import Swal from "sweetalert2";
import PrintIcon from '@mui/icons-material/Print';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { AiOutlineUser } from "react-icons/ai";
import { BsCart2 } from "react-icons/bs";
import Select from 'react-select'
import { HiOutlineArrowDown } from "react-icons/hi";
import { FaMotorcycle } from "react-icons/fa";
import { MdOutlineDeliveryDining ,MdPayment } from "react-icons/md";
import swal from 'sweetalert';
import { Diversity1TwoTone } from '@mui/icons-material';
import { MdModeEditOutline } from "react-icons/md";
// to error of current location 
const onError = (type, status) => console.log(type, status)

function Formuser(props){
  // to store audio 
  var LS = localStorage.getItem('objtotal');


  // if(LS != null)
  //    console.log("props.OrderItemLst", JSON.parse(LS));

  //    console.log("LS",LS)

  const storageConfigured = isStorageConfigured();
//to get current location 
const [CurrentLoc, setCurrentLoc] = useState('');
const handleResults = (results) => {
  setCurrentLoc(results.filter((item) => {

    return (item.types.indexOf('street_address') >= 0);
    
  })[0].formatted_address)
 }
 
// to put current location in input 
const InputChange= (Value) => setCurrentLoc(Value);
const { isLoaded } = useJsApiLoader({
  id: 'google-map-script',
  googleMapsApiKey: "AIzaSyASbQBHuvWt93PcUlu1F2HAjmrboNmNS2Q"

})
//to put input value 

// object that has details of order and details of user form 
const dataform={
 consumer:{ 
  phone:'',
  name:'',
  address:'',
  email:'',
  PaymentMethod:'',
  deliveryOrder:'',
  message:'',
  comment:'',
  record:'',
  DeliveryStatus:'',
  RegionName:'',
  RegionId:''

 },
 orderItemLst:[{
  detOrder:props.detOrder
 }],
 merchantMagazineId:'',
 file:'',
//  dateofdeliverd:'',
  //  comment:''
};
// {console.log(props.comment)}
// {console.log(props.flieup)}
// {console.log(dataform)}

// blob of record 
// const[mediaBlobUrl,setmediaBlobUrl]=useState('')
// to declare value of input form 
const[phone,setphone]=useState()
const[name,setname]=useState()
const[email,setemail]=useState()
const[dateofdeliverd,setdatedeliv]=useState()
const[paymentMethod,setpaymentMethod]=useState()
//to set value of input 
const handleChangephone = event => {
  setphone (event.target.value);

};

// console.log(props.comment)
const handleChangename = event => {
  setname (event.target.value);

};

const handleChangeemail = event => {
  setemail (event.target.value);  
};

const handleChangepaymentMethod = (id) => {
  setpaymentMethod(id);
};

const handleChangedate = event =>{
  setdatedeliv(event.target.value)
};

const[comment,setcomment]=useState()
const handleChangecomment = event => {
  setcomment (event.props.comment);

};
// {console.log(props.comment)}

//function of submit (set value of form and audio and order )
const handleItemSubmit = (event) => {
  event.preventDefault()
  dataform.consumer.phone=phone
  dataform.consumer.name=name
  dataform.consumer.email=email  
  dataform.consumer.address=CurrentLoc
  dataform.consumer.PaymentMethod=paymentMethod
  dataform.consumer.comment=props.comment
  if(regionvalue==null ||regionvalue==undefined)
{
  Swal.fire("يجب أدخال المنطقه")

}
  dataform.consumer.deliveryOrder=regionvalue.deliveryFees
  dataform.orderItemLst=props.OrderItemLst
  dataform.merchantMagazineId=props.merchantMagazineId
  dataform.consumer.record=props.flieup
  dataform.consumer.TimeDelivery=TimeDelivery
  dataform.consumer.DeliveryStatus=DeliveryStatus
  dataform.consumer.RegionId=regionvalue.regionId
  dataform.consumer.RegionName=regionvalue.regionName

  
  //window.location.reload();
  if(dataform.consumer.address==null)
{
  Swal.fire("يجب أدخال العنوان")


}  
else if(dataform.consumer.email==null)
{
  Swal.fire("يجب أدخال الأيميل")

}

else if(dataform.consumer.name==null)
{
  Swal.fire("يجب أدخال الأسم")


}
else if(dataform.consumer.name.match(/[0-9]/) )
{
  Swal.fire(" يجب أدخال الأسم بدون أرقام")


}
else if(dataform.consumer.PaymentMethod==null || dataform.consumer.PaymentMethod==undefined)
{
  Swal.fire("يجب أدخال طريقه السداد")


}
else if(dataform.consumer.phone==null)
{
  Swal.fire("يجب أدخال التليفون ")


}
else if(dataform.orderItemLst.length==0)
{
  Swal.fire("يجب اختيار المنتج  ")


}
else{

  onFileUpload()
  lsRememberMe()
//  createPost()
  importFile()

 
  
}


}
const MARGIN = 2;
const createScreenshotPDF = () => {
  // get the content element
  document.getElementById("divprint").style.display="block"
  const content = document.getElementById('divprint');
// console.log(content.offsetWidth)
// console.log(content.style.w)

// console.log(content.clientWidth)

  // get content size
  const width = content.offsetWidth ;
  const height = content.offsetHeight;

  // call html2canvas to save the screenshot to a pdf file
  html2canvas(content, {
    scrollX: 0, // scroll to X origin
    scrollY: 5, // scroll to Y origin
    scale: 5, // 5 is quality scale
  }).then(function (canvas) {
    // convert the canvas conent to a Data URI/URL
    var img = canvas.toDataURL('image/jpeg', 5); // 5 is quality scale
    var doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px', // set pixel as unit
      format: [width + MARGIN * 1, height + MARGIN * 1],
    });

    // add canvas as an image
    doc.addImage(img, 'JPEG', MARGIN, MARGIN, width, height);

    // save the content to screenshot.pdf
    doc.save('الطلبيه.pdf');
     window.location.reload()
  });
};
const importFilemobile= async (e) => {
  // console.log("importFilemobile",dataform)
  const formData = new FormData();
  formData.append("order", JSON.stringify(dataform));
  formData.append("file", dataform.consumer.record);
  try {
    const res = await axios.post("https://tijarymagazineapis.azurewebsites.net/SubmitOrder", formData)
    .then((resp) =>{
      Swal.fire({
        title: 'تم الأرسال',
        icon: 'success',
        timer: 2000,
        confirmButtonText: 'تم'
    }).then(() => {
      createScreenshotPDF()
   
    })
     })
   
  } catch (ex) {
    console.log(ex);
  }
};
const handleItemSubmitmobile = (event) => {
  event.preventDefault()
  dataform.consumer.phone=phone
  dataform.consumer.name=name
  dataform.consumer.email=email  
  dataform.consumer.address=CurrentLoc
  dataform.consumer.PaymentMethod=paymentMethod
  dataform.consumer.comment=props.comment
  if(regionvalue==null ||regionvalue==undefined)
{
  Swal.fire("يجب أدخال المنطقه")

}
  dataform.consumer.deliveryOrder=regionvalue.deliveryFees
  dataform.orderItemLst=props.OrderItemLst
  dataform.merchantMagazineId=props.merchantMagazineId
  dataform.consumer.record=props.flieup
  dataform.consumer.TimeDelivery=TimeDelivery
  dataform.consumer.DeliveryStatus=DeliveryStatus
  dataform.consumer.RegionId=regionvalue.regionId
  dataform.consumer.RegionName=regionvalue.regionName

  
  //window.location.reload();
  if(dataform.consumer.address==null)
{
  Swal.fire("يجب أدخال العنوان")


}  
else if(dataform.consumer.email==null)
{
  Swal.fire("يجب أدخال الأيميل")

}

else if(dataform.consumer.name==null)
{
  Swal.fire("يجب أدخال الأسم")


}
else if(dataform.consumer.name.match(/[0-9]/) )
{
  Swal.fire(" يجب أدخال الأسم بدون أرقام")


}
else if(dataform.consumer.PaymentMethod==null || dataform.consumer.PaymentMethod==undefined)
{
  Swal.fire("يجب أدخال طريقه السداد")


}
else if(dataform.consumer.phone==null)
{
  Swal.fire("يجب أدخال التليفون ")


}
else if(!dataform.consumer.phone.match(/^\d+$/))
{
  Swal.fire("تاكد من التليفون ")


}
else if(dataform.orderItemLst.length==0)
{
  Swal.fire("يجب اختيار المنتج  ")


}
else{

  onFileUpload()
  lsRememberMe()

  importFilemobile()

 
  
}


}
// {console.log(props.headerObj)}

//////////////////////handle button of time delivery///////////////////
             const [TimeDelivery, setTimedelivery] = useState(0);
             const[DeliveryStatus,setdeliverystatus]=useState(0);
             // btn of 2 hours
            const handlebchangetobtn2= event =>{
              event.preventDefault();
              setdeliverystatus(1);
              tooglebutton('delived');
                  // console.log(DeliveryStatus)
              }
              // btn of 3 hours
              const handlebchangetobtn3= event =>{
                event.preventDefault();

                setTimedelivery(0);
                tooglebuttonofdelivery('now');
                

                }
                // btn of 4 hours
                const handlebchangetobtn4= event =>{
                  event.preventDefault();
                  setTimedelivery(1);
                  tooglebuttonofdelivery('after2');

                  }
                   ///// btn  oof now 
                  const handlebchangetobtnnow= event =>{
                    event.preventDefault();
                    setdeliverystatus(0);
                    tooglebutton('hand');
                    }
                    //btn  tommorow morning
                    const handlebchangebtntomAm= event =>{
                      event.preventDefault();
                      setTimedelivery(2);
                      tooglebuttonofdelivery('tomM');

                      }
                      // btn tommorow evening 
                      const handlebchangebtntommidAm= event =>{
                        event.preventDefault();
                        setTimedelivery(3);
                        tooglebuttonofdelivery('tomE');

                        }
                        //btn tommorow night
                        const handlebchangebtntompm= event =>{
                          event.preventDefault();
                          setTimedelivery(4);
                          tooglebuttonofdelivery('tomN');

                          }

  
/////////////////////upload voice//////////////
const [blobList, setBlobList] = useState([]);
  // current file to upload into container
const [uploading, setUploading] = useState(false);
  const [inputKey, setInputKey] = useState(Math.random().toString(36).split());
  const onFileUpload = async () => {
    // prepare UI
    setUploading(true);
    // *** UPLOAD TO AZURE STORAGE ***
    const blobsInContainer = await uploadFileToBlob(props.flieup);
    // prepare UI for results
    setBlobList(blobsInContainer);
    // reset state/form
    setUploading(false);
    setInputKey(Math.random().toString(36));
     }
     ////////////////////remember me //////////////////////////////
// to use ref in remember me checked
 const ref = useRef(null)

// function remember me 
function lsRememberMe(){
  if (ref.current.checked){
    
    localStorage.setItem('email',email)
    localStorage.setItem('name',name)
    localStorage.setItem('phone',phone)
    localStorage.setItem('address',CurrentLoc) 
    localStorage.setItem('Reagon',JSON.stringify(regionvalue) ) 

    localStorage.setItem('rememberMe',ref.current.checked) 
   // localStorage.setItem('rememberMe',ref) 
     
  }
  else{
    localStorage.clear()
  }
  
}
///////////////////////////////////////// to put data when reload page ///////////////
useEffect(() => {
  setphone(localStorage.getItem('phone'));
  setname(localStorage.getItem('name'));
setCurrentLoc(localStorage.getItem('address'));
setemail(localStorage.getItem('email'));
// console.log("Reagon",localStorage.getItem('Reagon'));
var regon = localStorage.getItem('Reagon');
// console.log("Reagon2",regon);
// console.log("json Reagon2",JSON.parse(regon));
if(regon != null){
try
{
  var jsregon = JSON.parse(regon)
setregionvalue(jsregon);
setLSregionvalue(jsregon.regionId)
props.setdelvVal(jsregon.deliveryFees)
}
catch{}
}
if(localStorage.getItem('phone')!=''){
  ref.current.checked = true;

}
}, []);

const importFile= async (e) => {
  // console.log("importFile",dataform)
  const formData = new FormData();
  formData.append("order", JSON.stringify(dataform));
  formData.append("file", dataform.consumer.record);
  try {
    const res = await axios.post("https://tijarymagazineapis.azurewebsites.net/SubmitOrder", formData)
    .then((resp) =>{
      Swal.fire({
        title: 'تم الأرسال',
        icon: 'success',
        timer: 2000,
        confirmButtonText: 'تم'
    }).then(() => {
      document.getElementById("divprint").style.display="flex"
      var printContents = document.getElementById("divprint").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      document.getElementById("divprint").style.display="none"

      window.location.reload()  
      })
      
     })
      .catch(err => {
        console.log("resp",err)
      swal({
        text: err.response.data.msg,
        buttons: true,
        dangerMode: true,
      })
      console.log(err);
    });
  } catch (ex) {
    console.log(ex);
  }
};
function tooglebutton(DeliveryStatus){
  const x=document.getElementById('delived')
  const y=document.getElementById('hand')
  if(DeliveryStatus == 'delived'){
    y.style.background='red'
    y.style.color='white'

    x.style.background='white'
    x.style.border='1px solid #696969'
    x.style.color='#696969'

  }
  else{

    y.style.background='white'
    y.style.border='1px solid #696969'
    y.style.color='#696969'
    x.style.background='red'
    x.style.color='white'
  }

}
function tooglebuttonofdelivery(timeofdelivey){
  const x=document.getElementById('now')
  const y=document.getElementById('after2')
  const a=document.getElementById('tomM')
  const z = document.getElementById('tomE')
  const b=document.getElementById('tomN')
  if(timeofdelivey == 'now'){

    y.style.background='white'
    y.style.border='1px solid #696969'
    y.style.color='#696969'

  
        
    a.style.background='white'
    a.style.border='1px solid #696969'
    a.style.color='#696969'

    

        
    z.style.background='white'
    z.style.border='1px solid #696969'
    z.style.color='#696969'



    b.style.background='white'
    b.style.border='1px solid #696969'
    b.style.color='#696969'


    x.style.background='red'
    x.style.color='white'

  }
  else if(timeofdelivey == 'after2'){
    y.style.background='red'
    y.style.color='white'


    
    a.style.background='white'
    a.style.border='1px solid #696969'
    a.style.color='#696969'


   
    z.style.background='white'
    z.style.border='1px solid #696969'
    z.style.color='#696969'

    b.style.background='#696969'
    b.style.background='white'
    b.style.color='#696969'

    x.style.background='white'
    x.style.border='1px solid #696969'
    x.style.color='#696969'



  
  }
  else if(timeofdelivey == 'tomM'){
    a.style.background='red'
    a.style.color='white'

    z.style.background='white'
    z.style.border='1px solid #696969'
    z.style.color='#696969'

    b.style.background='white'
    b.style.border='1px solid #696969'
    b.style.color='#696969'

 
    x.style.background='white'
    x.style.border='1px solid #696969'
    x.style.color='#696969'

    y.style.background='white'
    y.style.border='1px solid #696969'
    y.style.color='#696969'


  }
  else if(timeofdelivey == 'tomE'){

    a.style.background='white'
    a.style.border='1px solid #696969'
    a.style.color='#696969'

    z.style.background='red'
    z.style.color='white'

    b.style.background='white'
    b.style.border='1px solid #696969'
    b.style.color='#696969'

    x.style.background='white'
    x.style.border='1px solid #696969'
    x.style.color='#696969'

    y.style.background='white'
    y.style.border='1px solid #696969'
    y.style.color='#696969'

    


  }
  else if(timeofdelivey == 'tomN'){
    a.style.background='white'
    a.style.border='1px solid #696969'
    a.style.color='#696969'

    z.style.background='white'
    z.style.border='1px solid #696969'
    z.style.color='#696969'

   
    b.style.background='red'
    b.style.color='white'

    x.style.background='white'
    x.style.border='1px solid #696969'
    x.style.color='#696969'

   
    y.style.background='white'
    y.style.border='1px solid #696969'
    y.style.color='#696969'

   


  }

}
function paymentmethod(Id){
  const AllPayBtn=document.getElementsByClassName('paymentbutton')
  const SelectedpayBtn =document.getElementById('paymentmethod'+Id)
  for (var i = 0; i < AllPayBtn.length; i++) {
    AllPayBtn[i].style.backgroundColor="white";
}
  SelectedpayBtn.style.background='#696969'
}
const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

const Print = () =>{     
  //console.log('print');  
  let printContents = document.getElementById('printablediv').innerHTML;
  let originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
 document.body.innerHTML = originalContents; 
}

const [selectedOptions, setSelectedOptions] = useState();
const optionList=props.headerObj.regions

const[regionvalue,setregionvalue]=useState()
 const[LSregionvalue,setLSregionvalue]=useState("-1")
 const[regionvaluecity,setregionvaluecity]=useState()


const setthevalueofregion = (SelectedReg,Region) => {
  // console.log("  selectedIndex  ",  SelectedReg.selectedIndex - 1)
  // console.log("  SelectedReg  ",  SelectedReg)
  // console.log("  document  ",  Region)
  // console.log("  documentref  ", Region[SelectedReg.selectedIndex - 1]  )
  setregionvaluecity(Region[SelectedReg.selectedIndex - 1].regionName)
  props.setregionValefun(Region[SelectedReg.selectedIndex - 1])
  // console.log(Region[SelectedReg.selectedIndex - 1])
  setregionvalue(Region[SelectedReg.selectedIndex - 1])
  setLSregionvalue(Region[SelectedReg.selectedIndex - 1].regionId)
  localStorage.setItem('Reagon',JSON.stringify(Region[SelectedReg.selectedIndex - 1]))
  props.setdelvVal(Region[SelectedReg.selectedIndex - 1].deliveryFees)
}

return(
  <div className='container-fluid fontmaaray mt-3'>
  

        <div className='row justify-content-center'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
  
         <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <FaRegUser  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center mt-1'>بيانات الشخصية</h1> 
         

         
            </div>
    </div>
    <form className='container-fluid' /*</div>onSubmit={handleSubmit}*/>
    <div className='row'> 
    <div>
      
  {/*  <div class="form-group">
    <input
          id="first_name"
          name="first_name"
          type="text"
          onChange={event => setFirstName(event.target.value)}
          value={firstName}
        />
        <input
          id="last_name"
          name="last_name"
          type="text"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
        />

</div>*/}

         <div className="form-group">
    <input type="text" className="form-control mt-3 " style={{'font-size': 'x-large'}}  id='phone'   name='phone' value={phone} onChange={handleChangephone}   placeholder="رقم موبيل " ></input>
  </div>
  <div className="form-group">
    
    <input type="text" className="form-control mt-2 "style={{'font-size': 'x-large'}} placeholder="الاسم" id='name' value={name}  name='name' onChange={handleChangename}></input>
    <input type="text" className=" inputshow" value={comment} name='comment'  onChange={handleChangecomment}></input>
  </div>
  <div className="form-group ">
  <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
          <div className='col-12'>
 <div className='d-flex mt-2'> 
 
 <div className='col-lg-10 col-10' style={{marginRight:"1%"}}>
 <div className='d-flex form-group' > 
<div className='col-12'  >

<select style={{height:"46px",border:"1px solid  #ced4da",borderRadius:"5px",width:"99%",outline:"none"
                ,marginLeft:"5px",textAlignLast:"right",direction:"rtl"  }} 
onChange={(event) => setthevalueofregion(event.target,optionList)} value={LSregionvalue}>
<option value="-1" disabled selected>من فضلك اختار المنطقه</option>
{optionList.map((reg, index) =>

      <option id='theregionvalue' key={index} value={reg.regionId}  >
       {reg.regionName}
      </option>
     )}
 </select>
</div>


</div>



<div className='col-12 mt-2'>
<input
        style={{ fontSiz:"14px",height:"46px",width:"99%",marginRight:0}}
   
        onChange={evt => InputChange(evt.target.value)} 
        
        className="mt-2  form-control text-area-width " type='text'  placeholder="العنوان" 
       value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>
</div>

 </div>
   
       <button if onClick={getCurrentLocation} disabled={loading} className='btn btn-warning col-1 btnwidth' style={{marginRight:0}}>
         <div className='text-white'>
         <IoLocation className='fs-5 text-center '></IoLocation>
         <p style={{ justifyContent:"center",display:"flex"}}>مكاني</p>
         </div> 
       </button>
       </div>
          </div>
        

      )}
    </CurrentLocation>
  
  {/* <CurrentLocation onFetchAddress={handleResults} onError={onError}>
      {({ getCurrentLocation, loading }) => (
           <div className='row mt-2'> 
  
         <input
         onChangoe={evt => InputChange(evt.target.value) } 
         className="col-lg-11   form-control text-area-width" style={{'width': '80%','margin-right': '1%' ,'font-size': 'x-large'}} type='text'  placeholder="العنوان" 
        value={CurrentLoc}  name='CurrentLoc' id='CurrentLoc'></input>

        <button if onClick={getCurrentLocation}  disabled={loading} style={{'width':'17%','margin-right':'1%','margin-top':'0px' }} className='btn btn-warning col-sm-1 btnwidth '>
          <div className='text-white mt-1'>
          <IoLocation className='text-center fs-2 ' style={{'width':'100%'}}></IoLocation>
           <p className=' fs-4'>مكاني</p>
          </div> 
        </button>
        </div>
        

      )}
    </CurrentLocation> */}

 
  </div>
  <div className="form-group">
    
    <input type="email" className="form-control mt-2 " style={{'font-size': 'x-large'}} placeholder="الايميل" onChange={handleChangeemail} value={email} id='email' name='email'></input>
  </div>
  <div className='row mt-3'>
  
 
  {isDesktopOrLaptop &&
  <div className='row'>
    <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%','margin-right': '-120px'}} ref={ref} id="rememberMe"></input>
  </div>
   <div className=' col-lg-6 col-10'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success' style={{'margin-right': '-350px'}}  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div>
   }
   {isTabletOrMobile &&
   <div className='row'>
     <div className='col-2'> <input type='checkbox' style={{'width':'100%','height':'100%'}} ref={ref} id="rememberMe"></input>
  </div>
    <div className='col-lg-6 col-10'>
   <label for='rememberMe' name="rememberMe"> <span  className='fs-5 text-success'  >  الاحتفاظ بالبيانات الشخصيه لاستخدمها في المره القادمه</span></label>
   </div>
   </div> }
   

  </div>

  <div className='row  justify-content-center mt-2 '>
  <input value={TimeDelivery}  onChange={handleChangedate} id='date' name='date' className='hidedeliveredinput'></input>
    <button style={{'width':'45%' ,'height':'10%','margin-left':'4%','background':'red','font-weight': 'bold',color:"white" }} className=' btn ' id='delived' type="button" onClick={handlebchangetobtnnow} >توصيل للعميل  </button>
    <button  style={{'width':'45%','margin-left':'2%','background':'white',border:"1px solid #696969"}} onClick={handlebchangetobtn2} id='hand' className='btn ' type="button" value='1'>استلام من محل  </button>

    </div>
  <hr></hr>

  <div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <MdOutlineDeliveryDining  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'> اختار ميعاد التوصيل  </h1> 
         

         
            </div>
    </div>
  
  <div className='row mt-3'>


<div className='row'>
 
 </div>
<div id='displaybtn' className='container-fluid'>

    <div className='row justify-content-center mt-2 '>
  <button style={{'width':'94%','margin-left':'2%','background':'red','font-weight': 'bold',color:"white" }} className='btn  btn-white ' id='now' type="button" onClick={handlebchangetobtn3}>اقرب وقت</button>
</div>
<div className='row justify-content-center  mt-2' >
<button style={{'width':'22%','margin-left':'2%','background':'white',border:"1px solid #696969",color:"#696969"}}  className='btn btn-white '  id='after2' type="button" onClick={handlebchangetobtn4}>بعد 2 ساعه  </button>

    <button style={{'width':'22%','margin-left':'2%','background':'white',border:"1px solid #696969",color:"#696969"}} className='btn btn-white 'id='tomM' type="button" onClick={handlebchangebtntomAm} >   غداً &nbsp; &nbsp; صباحا. </button>
  <button  style={{'width':'22%','margin-left':'2%','background':'white',border:"1px solid #696969",color:"#696969"}} className='btn btn-white' id='tomE' type="button" onClick={handlebchangebtntommidAm}>غداً&nbsp;&nbsp;  ظهرا . </button>
  <button style={{'width':'22%','margin-left':'2%','background':'white',border:"1px solid #696969",color:"#696969"}} className='btn btn-white ' id='tomN' type="button" onClick={handlebchangebtntompm}>غداً  &nbsp; &nbsp;مساءاً. </button>
</div>
</div>

</div>
<hr className='mt-4' ></hr>

<div className='container-fluid bord'>
        <div className='row bg-primary roundedtitle'>
          <h1 className='col-1'>  <MdPayment  className=' text-white fs-1 mt-2' />  </h1>
            <h1 className='text-light col-10 text-center'>طريقه السداد</h1> 
         

         
            </div>
    </div>


<div className="row mt-4">
  
   

    {props.headerObj.payments.map((Slider, index) => (
          <div className='col-4'>

  {/*<input style={{'display':'none'}} type="radio" id="louie" name={'visa'} value={Slider.id} onChange={handleChangepaymentMethod} />*/}
  <label for={Slider.paymentName}> 
  <button className='paymentbutton ' style={{'border':'none',
   'color':'white' ,background:"none"}} name={'visa'} id={'paymentmethod' + Slider.id} onClick={(e) =>{paymentmethod(Slider.id)(e.preventDefault())}}>

<img className='widthofmezza ' style={{height:"80%" }} src={Slider.paymentIcon} value={Slider.id} name={'visa'} onClick={() => handleChangepaymentMethod(Slider.id)} />
</button>
</label>
</div>

    ))}
    

</div>
<div className='row'>

<div className='col-lg-12 col-12 sendindesktop' >
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheightdes " onClick={handleItemSubmit}><FiSend className='fs-1 mx-4 mt-2'>
  
  </FiSend>
  ارسل  </button>
</div>
<div className='col-lg-12 col-12 sendindmobile'>
<button  className="btn btn-success w-100 mt-3 fs-1 text-white  submitheightmob sendindmobile" onClick={handleItemSubmitmobile}><FiSend className='fs-1 mx-4 mt-2'>
  
  </FiSend>
  ارسل  </button>
</div>



 
 
    </div>
 
  <div >
 
    </div>
  </div>
  </div>
 
</form>

</div>

</div>
<div className='container-fluid mt-3' id='divprint' style={{display:"none"}} >
    <div className='row justify-content-center'> 
    <div dir="rtl" className='col-lg-11 col-sm-12 col-md-10 '>
    <div className='container-fluid bord'>

        <div className='row bg-primary justify-content-center mt-1 roundedtitle'>
        <h1 className='col-1'>  <BsCart2  className=' text-white fs-1 mt-2' />  </h1>   
            <h1 className='text-light col-10 text-center fontmaaray mt-1'>اوردر</h1>
            </div>
            {/*table of order item */}  
<div className='row'>
<h6 className='col-2 fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>كميه</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>صنف</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>خصم</h6>
                <h6 className='col-3  fw-bold fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>سعر</h6>
                <h6 className='col-2 fw-bold  fontmaaray' style={{ 'color': props.headerObj.fontcolor }}>إجمالي</h6>

</div>

                

 
    {/*maping from list to show order details [name, quntity,total discount,total ] */}
{props.OrderItemLst.map(order => (
  <div className='row justify-content-center  mt-2 fontmaaray' style={{border:"1px solid #eaebeb"}} id='myTablephone'>
  
  <div className='col-12 mt-2'> 
  <div className='row'>
  <div className='col-2 '>
  {/* style={{'color':props.headerObj.fontcolor}} */}

 
<h6 className='fontmaaray ' style={{fontWeight:"bold",color:"#bb0202"}}  >{order.qty}</h6>



</div>
<div className='col-10 p-0'>

<h6  className=' fontmaaray pe-4  ' style={{ 'color': props.headerObj.fontcolor, textAlign: "right" }}>{order.itemName}</h6>




</div>

<div className='row'>
  <div className='col-5'>

  </div>
{order.itemDiscountAmount==0?

<div className='col-2'>
<p>{order.discount}</p>

</div>
:<div className='col-2'>

</div>}
<div className='col-3' style={{textAlign:"left"}}>
<p>{order.netprice.toLocaleString()}
</p>
</div>
<div className='col-2 p-0'>

<h6 className=' fontmaaray '  style={{color:"#bb0202",fontWeight:"bold"}}>{order.total.toLocaleString()}</h6>


</div>


</div>



  </div>
  
  </div>

  
</div>


))}
{/*total of order  */}

  

    
</div>
{LS ? <div className='container-fluid mt-2'>
  <div className='row' >
    <div className='row'>
    <h6 className='col-lg-4 fs-4 col-5 fontmaaray' style={{ 'white-space':'nowrap','color':props.headerObj.fontcolor}}>  عدد الأصناف </h6> 
    <h6 className='col-lg-2 fs-4 col-3 fontmaaray '  style={{'text-align':'left' , 'color':props.headerObj.fontcolor}}>({JSON.parse(LS).totalqut.toLocaleString()})</h6>
    <hr className='mt-3 col-lg-5 col-3 fontmaaray' style={{'margin-right': '6%'}}></hr>

    </div>

    <div className='row '>
      <h6 className='col-3 fs-4 fontmaaray' style={{'color':props.headerObj.fontcolor}}> الوفر</h6>
      <hr className='mt-3 col-10 fontmaaray' style={{'width':'60%' ,'color':'white'}}></hr>

      <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right' ,'color':props.headerObj.fontcolor}}> {parseInt(JSON.parse(LS).totaldiscount ).toFixed(2)}</h6>

    </div>
    <div className='row'>
      <h6 className='col-4 fs-4 fontmaaray'  style={{'color':props.headerObj.fontcolor}}> دليفري</h6>
      <hr className='mt-3 col-10 fontmaaray' style={{'width':'52%' ,'color':'white'}}></hr>
{
props.delvVal == undefined ? 
      <h6 className='col-1 fs-4 fontmaaray' style={{'text-align':'right' ,'color':props.headerObj.fontcolor}}>{parseInt(props.headerObj.defaultDeliveryPrice).toFixed(2)}</h6>
: <h6 className='col-1 fs-4 fontmaaray' style={{'text-align':'right' ,'color':props.headerObj.fontcolor}}>{parseInt(props.delvVal).toFixed(2)}</h6>
}

    </div>
    {LS.Nettotal==0?
    
     <div className='row'>
      <h6 className='col-6 fs-4 fontmaaray' style={{'white-space':'nowrap','color':props.headerObj.fontcolor}}>صافي الإجمالي </h6>
      <hr className='mt-3 col-7 fontmaaray' style={{ 'color':'white','width':'35.5%'}}></hr>

      <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}>{(JSON.parse(LS).Nettotal+props.headerObj.defaultDeliveryPrice).toLocaleString(2) }</h6>

    </div>
    
    
    
    
    :    <div className='row'>
      <h6 className='col-6 fs-4 fontmaaray' style={{'white-space':'nowrap','color':props.headerObj.fontcolor}}>صافي الإجمالي </h6>
      <hr className='mt-3 col-7 fontmaaray' style={{ 'color':'white','width':'35.5%'}}></hr>

      <h6 className='col-1 fs-4 fontmaaray'  style={{'text-align':'right','color':props.headerObj.fontcolor}}>{(JSON.parse(LS).Nettotal+props.headerObj.defaultDeliveryPrice).toLocaleString(2) }</h6>

    </div>}

  </div>
  

</div> :""}


</div>


</div>

</div>
</div>

)
}

export default Formuser ;